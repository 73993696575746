import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49ee8950"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = ["dir"]
const _hoisted_3 = { class: "cards" }
const _hoisted_4 = { class: "card-head" }
const _hoisted_5 = ["dir"]
const _hoisted_6 = { class: "card-head" }
const _hoisted_7 = ["dir"]
const _hoisted_8 = { class: "card-head" }
const _hoisted_9 = ["dir"]
const _hoisted_10 = { class: "card-head" }
const _hoisted_11 = ["dir"]
const _hoisted_12 = {
  key: 0,
  class: "foot"
}

export function render(_ctx, _cache) {
  const _component_NavBarTop = _resolveComponent("NavBarTop")
  const _component_ChatLineRound = _resolveComponent("ChatLineRound")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_col = _resolveComponent("el-col")
  const _component_VideoPlay = _resolveComponent("VideoPlay")
  const _component_Document = _resolveComponent("Document")
  const _component_Iphone = _resolveComponent("Iphone")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NavBarTop),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "title",
        dir: _ctx.langModel && _ctx.langModel.align == 'left' ? 'ltr' : 'rtl'
      }, _toDisplayString(_ctx.dictWelcome), 9, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              xs: 24,
              sm: 12,
              md: 8,
              lg: 6,
              xl: 6
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "card-info",
                  onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onInfoClick && _ctx.onInfoClick(...args)))
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_el_icon, { size: 100 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ChatLineRound)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", {
                    class: "card-body",
                    dir: _ctx.langModel && _ctx.langModel.align == 'right' ? 'rtl' : 'ltr'
                  }, _toDisplayString(_ctx.dictInfos), 9, _hoisted_5)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              xs: 24,
              sm: 12,
              md: 8,
              lg: 6,
              xl: 6
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "card-info",
                  onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.onVideoClick && _ctx.onVideoClick(...args)))
                }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_el_icon, { size: 100 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_VideoPlay)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", {
                    class: "card-body",
                    dir: _ctx.langModel && _ctx.langModel.align == 'right' ? 'rtl' : 'ltr'
                  }, _toDisplayString(_ctx.dictVideos), 9, _hoisted_7)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              xs: 24,
              sm: 12,
              md: 8,
              lg: 6,
              xl: 6
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "card-info",
                  onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.onHandoutClick && _ctx.onHandoutClick(...args)))
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_el_icon, { size: 100 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Document)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", {
                    class: "card-body",
                    dir: _ctx.langModel && _ctx.langModel.align == 'right' ? 'rtl' : 'ltr'
                  }, _toDisplayString(_ctx.dictHandout), 9, _hoisted_9)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              xs: 24,
              sm: 12,
              md: 8,
              lg: 6,
              xl: 6
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "card-info",
                  onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.onAppClick && _ctx.onAppClick(...args)))
                }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_el_icon, { size: 100 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Iphone)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", {
                    class: "card-body",
                    dir: _ctx.langModel && _ctx.langModel.align == 'right' ? 'rtl' : 'ltr'
                  }, _toDisplayString(_ctx.dictApp), 9, _hoisted_11)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.$windowWidth < _ctx.$windowHeight)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, " "))
      : _createCommentVNode("", true)
  ]))
}