import { Vue, Options } from 'vue-class-component';
import CookieBanner from '@/components/CookieBanner/CookieBanner.vue';
import ApiService from '../../services/ApiService';
import { ChatLineRound, Document, VideoPlay, Iphone, Message } from '@element-plus/icons-vue';

@Options(
{
    props:
    {
        activeMenu: 
        {
            type: String,
            required: false
        }
    },
    components:
    {
        ChatLineRound,
        Document,
        Iphone,
        VideoPlay,
        Message,
        CookieBanner
    }
})

export default class NavBarTop extends Vue
{
    public activeMenu: string | undefined;

    private environmentCode : string = '';
    private version : string = '';
    private language : string = 'en';
    private showMenu : boolean = false;
    private showLanguage : boolean = false;
    private activeIndex: string = "1";
    private languageid : number = 0;
    private languageList : LanguageModel[] | undefined = [] as LanguageModel[];

    public handleSelect(key : number) 
    {
        switch (key)
        {
            case 1:
                this.$router.push(`/info/${this.language}`).catch(()=>{});
                break;
            
            case 2:
                this.$router.push(`/video/${this.language}`).catch(()=>{});
                break;

            case 3:
                this.$router.push(`/app/${this.language}`).catch(()=>{});
                break;

            case 4:
                this.$router.push(`/handout/${this.language}`).catch(()=>{});
                break;

            case 5:
                this.$router.push(`/contact/${this.language}`).catch(()=>{});
                break;
        }
    }

    public async mounted()
    {
        // get language from url parameter, or load from local storage
        if (this.$route.params.lang)
        {
            this.language = this.$route.params.lang.toString();
        }
        else
        {
            this.language = localStorage.getItem("languagecode")!;
        }

        // load all languages for selection
        this.languageList = await ApiService.getAllLanguages();

        this.onLanguageChange(this.language);

        if (!localStorage.getItem("languageid"))
        {
            localStorage.setItem("languageid", "39");
            localStorage.setItem("languagecode", "en");
        }

        if (this.languageList)
        {
            this.showLanguage = true;
        }
    }

    private planNewRoute(language : string)
    {

        if (this.$router.currentRoute.value.name == "chapter")
        {
            this.$router.push(`/chapter/${this.$router.currentRoute.value.params.chapterid}/${localStorage.getItem("languageid")}`).catch(()=>{});
        }
        else if (this.$router.currentRoute.value.name == "handout")
        {
            // only reroute on language change when no translation is shown
            if (!this.$route.params.l1 && !this.$route.params.l2)
            {
                this.$router.push(`/${String(this.$router.currentRoute.value.name)}/${language}`).catch(()=>{});
            }
        }
        else
        {
            this.$router.push(`/${String(this.$router.currentRoute.value.name)}/${language}`).catch(()=>{});
        }
    }

    private onHomeClick()
    {
        this.$router.push(`/site/${this.language}`).catch(()=>{});
    }

    private onLanguageChange(language : string)
    {
        if (this.languageList)
        {
            for (let l of this.languageList)
            {
                if (l.code == language)
                {
                    localStorage.setItem("languageid", l.languageid.toString());
                    localStorage.setItem("languagecode", l.code);
                    break;
                }
            }
        }

        this.planNewRoute(language);
    }
}
