import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4aa198b8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "chapter-list" }

export function render(_ctx, _cache) {
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _component_ChapterTranslation = _resolveComponent("ChapterTranslation")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 11,
              class: "header"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.firstLanguageName), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 12,
              class: "header"
            }, {
              default: _withCtx(() => [
                _createElementVNode("h2", null, _toDisplayString(_ctx.secondLanguageName), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedChapters, (chapterid) => {
          return (_openBlock(), _createBlock(_component_ChapterTranslation, {
            key: chapterid,
            chapterid: chapterid,
            firstLangId: _ctx.firstLanguage,
            secondLangId: _ctx.secondLanguage
          }, null, 8, ["chapterid", "firstLangId", "secondLangId"]))
        }), 128))
      ])
    ])), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}