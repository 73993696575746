import { Vue, Options } from 'vue-class-component';
import ApiService from '@/services/ApiService';

@Options(
{
    props:
    {
        chapterid: { type: Number, required: true },
        languageid: { type: Number, required: true },
        direction: { type: String, required: false }
    }
})


export default class ChapterItem extends Vue 
{
    public chapterid: number | undefined;
    public languageid: number | undefined;
    public direction: string | undefined;

    private chapterLoaded : boolean = false;
    private chapterModel : ChapterModel | undefined;
    private translationModel : TranslationModel | undefined;
    private fileModel : FileModel | undefined;
    private title : string = '';

    public async mounted()
    {
        if (this.chapterid && this.chapterid > 0 && this.languageid && this.languageid > 0)
        {
            // load chapter from db
            this.chapterModel = await ApiService.getChapter(this.chapterid.toString());

            if (this.chapterModel)
            {
                // load translation for selected language
                this.translationModel = await ApiService.getTranslation(this.chapterModel.chapterid.toString(), this.languageid.toString());

                if (this.translationModel)
                {
                    // set title according to language
                    this.title = this.translationModel.title;

                    if (!this.title) this.title = this.chapterModel.title;

                    // load banner file for chapter
                    this.fileModel = await ApiService.getBanner(this.chapterModel.chapterid.toString());

                    if (this.fileModel)
                    {
                        this.chapterLoaded = true;
                    }
                }
            }
        }
    }

    private onChapterClick()
    {
        if (this.chapterModel)
        {
            this.$router.push(`/chapter/${this.chapterModel.chapterid}/${this.languageid}`).catch(()=>{});
        }
    }
}
