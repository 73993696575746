import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74db126c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createBlock(_Transition, { name: "el-fade-in-linear" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass('video-'+_ctx.type)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass('title-'+_ctx.type)
        }, _toDisplayString(_ctx.name), 3),
        _createElementVNode("iframe", {
          class: _normalizeClass('youtube-'+_ctx.type),
          src: _ctx.link,
          title: "YouTube video player",
          frameborder: "0",
          allow: "accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: ""
        }, null, 10, _hoisted_1)
      ], 2)
    ]),
    _: 1
  }))
}