import { Vue, Options } from 'vue-class-component';
import ApiService from '@/services/ApiService';

@Options(
{
    props:
    {
        lang: {type: String, required: true},
        link: {tpye: String, required: true},
        type: {type: String, required: true}
    }
})

export default class VideoItem extends Vue 
{
    public lang: string | undefined;
    public link: string | undefined;
    public type: number | undefined;
    
    private name : string = "";
    private languageModel : LanguageModel | undefined;

    public async mounted()
    {
        if (this.lang && this.type)
        {
            this.languageModel = await ApiService.getLanguageByCode(this.lang);

            if (this.languageModel)
            {
                this.name = `${this.languageModel.name} / ${this.languageModel.native}`;
            }
        }
    }
}
