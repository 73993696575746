import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Site from '../views/Site/Site.vue'
import App from '../views/App/App.vue'
import Info from '../views/Info/Info.vue'
import Chapter from '../views/Chapter/Chapter.vue'
import Video from '../views/Video/Video.vue'
import Contact from '../views/Contact/Contact.vue'
import Handout from '../views/Handout/Handout.vue'
import Print from '../views/Print/Print.vue'

const routes: Array<RouteRecordRaw> = 
[
    {
        path: '/',
        redirect: '/site'
    },
    {
        path: '/site',
        redirect: '/site/en'
    },
    {
        path: '/site/:lang',
        name: 'site',
        component: Site,
    },
    {
        path: '/info',
        redirect: '/info/en'
    },
    {
        path: '/info/:lang',
        name: 'info',
        component: Info,
    },
    {
        path: '/chapter/:chapterid/:languageid',
        name: 'chapter',
        component: Chapter,
    },
    {
        path: '/video',
        redirect: '/video/en'
    },
    {
        path: '/video/:lang',
        name: 'video',
        component: Video
    },
    {
        path: '/app',
        redirect: '/app/en'
    },
    {
        path: '/app/:lang',
        name: 'app',
        component: App
    },
    {
        path: '/handout',
        redirect: '/handout/en',
    },
    {
        path: '/handout/:lang',
        name: 'handout',
        component: Handout,
        children:
        [
            {
                path: ':l1/:l2/:chapters',
                name: 'handout',
                component: Handout,
            }
        ]
    },
    {
        path: '/print/:lang/:l1/:l2/:chapters',
        name: 'print',
        component: Print,
    },
    {
        path: '/contact',
        redirect: '/contact/en'
    },
    {
        path: '/contact/:lang',
        name: 'contact',
        component: Contact
    }
]

const router = createRouter(
{
    history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
    routes
});

export default router


/*,
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "site"  '../views/AboutView.vue')
  }*/
