import { Vue, Options } from 'vue-class-component';
import ApiService from '@/services/ApiService';

@Options(
{
    props:
    {
        chapterid: { type: Number, required: true},
        firstLangId: { type: Number, required: true},
        secondLangId: { type: Number, required: true}
    }
})

export default class ChapterTranslation extends Vue 
{
    public chapterid: number | undefined;
    public firstLangId: number | undefined;
    public secondLangId: number | undefined;

    private chapterLoaded : boolean = false;
    private translationAModel : TranslationModel | undefined;
    private translationBModel : TranslationModel | undefined;
    private languageModel : LanguageModel | undefined;
    private firstTitle : string = '';
    private secondTitle : string = '';
    private firstText : string = '';
    private secondText : string = '';
    private firstTextAlign : string = 'left';
    private secondTextAlign : string = 'left';
    private displayType : string = 'full';
    private loading : boolean = true;

    public async mounted()
    {
        if (this.chapterid && this.chapterid > 0 && this.firstLangId)
        {
            // load translations for given languages
            this.translationAModel = await ApiService.getTranslation(this.chapterid.toString(), this.firstLangId.toString());
            if (this.translationAModel)
            {
                // load language settings
                this.languageModel = await ApiService.getLanguage(this.translationAModel.languageid.toString());

                if (this.languageModel)
                {
                    // set title according to language
                    this.firstTitle = this.translationAModel.title;
                    this.firstText = this.translationAModel.content;
                    this.firstTextAlign = this.languageModel.align;
                }
            }

            if (this.secondLangId && this.secondLangId > 0)
            {
                this.displayType = 'left';

                this.translationBModel = await ApiService.getTranslation(this.chapterid.toString(), this.secondLangId.toString());
                if (this.translationBModel)
                {
                    // load language settings
                    this.languageModel = await ApiService.getLanguage(this.translationBModel.languageid.toString());

                    if (this.languageModel)
                    {
                        // set title according to language
                        this.secondTitle = this.translationBModel.title;
                        this.secondText = this.translationBModel.content;
                        this.secondTextAlign = this.languageModel.align;
                    }
                }
            }

            this.loading = false;
        }
    }
}
