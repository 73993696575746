import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4098948"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = ["dir"]
const _hoisted_3 = { class: "chapter-list" }

export function render(_ctx, _cache) {
  const _component_NavBarTop = _resolveComponent("NavBarTop")
  const _component_ChapterItem = _resolveComponent("ChapterItem")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NavBarTop),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "welcome",
        dir: _ctx.langModel.align == 'left' ? 'ltr' : 'rtl'
      }, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.dictWelcome), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.dictInfoHeader), 1)
      ], 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.chapterModels != undefined)
          ? (_openBlock(), _createBlock(_component_el_row, {
              key: 0,
              gutter: 20
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapterModels, (item) => {
                  return (_openBlock(), _createBlock(_component_el_col, {
                    span: Math.round(24/(_ctx.$windowWidth/300)),
                    key: item.chapterid
                  }, {
                    default: _withCtx(() => [
                      (item.publish == 1)
                        ? (_openBlock(), _createBlock(_component_ChapterItem, {
                            key: 0,
                            chapterid: item.chapterid,
                            languageid: _ctx.langModel.languageid,
                            direction: _ctx.langModel.align
                          }, null, 8, ["chapterid", "languageid", "direction"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1032, ["span"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}