import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-463f6bf0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["dir"]
const _hoisted_2 = {
  key: 0,
  class: "title",
  style: {"'font-size":"min(14px,'+Math.floor(10*(90/(title ? title.length : 10)))+'px)"}
}
const _hoisted_3 = ["src"]

export function render(_ctx, _cache) {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock(_Transition, { name: "el-fade-in-linear" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "chapter",
        onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.onChapterClick && _ctx.onChapterClick(...args))),
        dir: _ctx.direction == 'left' ? 'ltr' : 'rtl',
        style: _normalizeStyle('text-align:'+_ctx.direction)
      }, [
        (_ctx.chapterLoaded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString((_ctx.title ? _ctx.title : '???')), 1))
          : _createCommentVNode("", true),
        (_ctx.chapterLoaded)
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "banner",
              src: 'data:image/png;base64,'+_ctx.fileModel.data
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ], 12, _hoisted_1)), [
        [_directive_loading, !_ctx.chapterLoaded]
      ])
    ]),
    _: 1
  }))
}