import { Vue, Options } from 'vue-class-component';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import VideoItem from '@/components/VideoItem/VideoItem.vue';
import ApiService from '@/services/ApiService';

interface VideoListItem
{
    lang : string;
    url : string;
}

@Options(
{
    components:
    {
        NavBarTop,
        VideoItem
    }
})
    

export default class Video extends Vue 
{
    private lang : string = 'English';
    private langModel : LanguageModel | undefined;
    private loading : boolean = true;
    private mainVideo : VideoListItem | null = null;
    private videoList : VideoListItem[] = [] as VideoListItem[];
    private dictHeaderTitle : string | undefined = 'Videos';
    private dictHeaderText : string | undefined = 'These videos provide essential tuberculosis information in multiple languages. Courtesy of LHL International Tuberculosis Foundation and Vestre Viken Hospital Trust (Norway).';
    
    public async mounted()
    {
        // get language from params
        this.lang = String(this.$route.params.lang);

        this.langModel = await ApiService.getLanguageByCode(this.lang);

        await this.loadDict();
        await this.loadVideoList();
        await this.getMainVideo();
        
        this.loading = false;
    }

    private async loadDict()
    {
        if (this.langModel)
        {
            this.dictHeaderTitle = await ApiService.getText("VIDEOS", this.langModel.languageid);
            this.dictHeaderText = await ApiService.getText("VIDEOS_HEADER", this.langModel.languageid);
        }
    }

    private async getMainVideo()
    {
        for (let video of this.videoList)
        {
            if (video.lang == this.lang)
            {
                this.mainVideo = {} as VideoListItem;
                this.mainVideo.lang = video.lang;
                this.mainVideo.url = video.url;
                break;
            }
        }
    }

    private async loadVideoList()
    {
        let item = {} as VideoListItem;
        item.lang = 'am';
        item.url = 'https://www.youtube.com/embed/YhNrTZI_UNQ';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'ar';
        item.url = 'https://www.youtube.com/embed/qvk91onejmc';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'dr';
        item.url = 'https://www.youtube.com/embed/g_XdPa9xMkE';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'en';
        item.url = 'https://www.youtube.com/embed/1BGcshoLYZ0';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'fr';
        item.url = 'https://www.youtube.com/embed/A0myjqG4JJQ';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'no';
        item.url = 'https://www.youtube.com/embed/zZaVt0QrHKA';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'ps';
        item.url = 'https://www.youtube.com/embed/CyP2hFbC74k';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'ro';
        item.url = 'https://www.youtube.com/embed/w-wQbUZPhuA';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'ru';
        item.url = 'https://www.youtube.com/embed/mm4TDn4L9q4';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'so';
        item.url = 'https://www.youtube.com/embed/hSykWBjIP7M';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'ti';
        item.url = 'https://www.youtube.com/embed/gOiOMoFIhV0';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'uk';
        item.url = 'https://www.youtube.com/embed/NhMsGxU7bFI';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'ur';
        item.url = 'https://www.youtube.com/embed/ZedNqMvXqww';
        this.videoList.push(item);

        item = {} as VideoListItem;
        item.lang = 'vi';
        item.url = 'https://www.youtube.com/embed/diOGKLpZ_yE';
        this.videoList.push(item);
    }
}
