import { Vue, Options } from 'vue-class-component';
//import 'element-ui/lib/theme-chalk/index.css';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
    }
})
    

export default class App extends Vue 
{
    private lang : string = 'English';
    private loading : boolean = true;
    private langModel : LanguageModel | undefined;
    private dictHeaderTitle : string | undefined = 'Videos';
    private dictHeaderText : string | undefined = 'These videos provide essential tuberculosis information in multiple languages. Courtesy of LHL International Tuberculosis Foundation and Vestre Viken Hospital Trust (Norway).';

    public async mounted()
    {
        // get language from params
        this.lang = String(this.$route.params.lang);

        this.langModel = await ApiService.getLanguageByCode(this.lang);

        await this.loadDict();

        this.loading = false;
    }

    private async loadDict()
    {
        if (this.langModel)
        {
            this.dictHeaderTitle = await ApiService.getText("APP", this.langModel.languageid);
            this.dictHeaderText = await ApiService.getText("APP_HEADER", this.langModel.languageid);
        }
    }
}
