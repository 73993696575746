<script setup lang="ts">
</script>

<template>
    <div id="app">
        <RouterView :key="$route.fullPath" />
    </div>
</template>

<style src="../public/css/site.css"></style>

