import { Vue, Options } from 'vue-class-component';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import ApiService from '@/services/ApiService';
import { ChatLineRound, Document, Iphone, VideoPlay } from '@element-plus/icons-vue';

@Options(
{
    components:
    {
        NavBarTop,
        ChatLineRound,
        Document,
        Iphone,
        VideoPlay
    },
}) 

export default class Site extends Vue 
{
    private lang : string = 'English';
    private langModel : LanguageModel | undefined = {} as LanguageModel;
    private loading : boolean = true;
    private dictWelcome : string | undefined = 'Welcome to ExplainTB';
    private dictInfos : string | undefined = 'Explain TB offers tuberculosis information in more than 30 languages.';
    private dictVideos : string | undefined = 'Videos provide essential tuberculosis information in multiple languages.';
    private dictApp : string | undefined = 'The ExplainTB app for Android and iPhone offers 41 chapters of patient information in 36 languages.';
    private dictHandout : string | undefined = 'Create bilingual handouts for your patients and share them across platforms.';


    public async mounted()
    {
        // get language from params
        this.lang = this.$route.params.lang.toString();
        this.langModel = await ApiService.getLanguageByCode(this.lang);

        // load dictionary
        await this.loadDict();

        //console.log($cookies);

        // create google tracking event
        /*
        let event : any = {};
        event.event = "visit_site";
        event.category = 'visit_site';
        event.action = 'visit_site';
        event.label = `visit_site`;
        this.$gtm.trackEvent(event);
        */

        this.loading = false;
    }

    private async loadDict()
    {
        if (this.langModel)
        {
            this.dictWelcome = await ApiService.getText("SITE_WELCOME", this.langModel.languageid);
            this.dictInfos = await ApiService.getText("SITE_CARD_INFO", this.langModel.languageid);
            this.dictVideos = await ApiService.getText("SITE_CARD_VIDEO", this.langModel.languageid);
            this.dictApp = await ApiService.getText("SITE_CARD_APP", this.langModel.languageid);
            this.dictHandout = await ApiService.getText("SITE_CARD_HANDOUT", this.langModel.languageid);
        }
    }

    private onInfoClick()
    {
        this.$router.push(`/info/${this.lang}`);
    }

    private onVideoClick()
    {
        this.$router.push(`/video/${this.lang}`);
    }

    private onAppClick()
    {
        this.$router.push(`/app/${this.lang}`);
    }

    private onHandoutClick()
    {
        this.$router.push(`/handout/${this.lang}`);
    }
}
