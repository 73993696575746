import axios from 'axios';

axios.defaults.withCredentials = true;

export default class ApiService
{
    public static API_ENV : string = process.env.VUE_APP_ENVIRONMENT;
    public static API_PATH : string = `${process.env.VUE_APP_REST_PATH}`;
    public static QR_PATH : string = `${process.env.VUE_APP_QR_PATH}`;
    public static LANGUAGEID_EN : number = 39;

    public static async getAllChapters()
    {
        try
        {
            
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'table');
            formData.append('table', 'chapter');

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ChapterModel[];
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async getAllLanguages()
    {
        try
        {
            
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'table');
            formData.append('table', 'language');

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as LanguageModel[];
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async getLanguage(languageid : string)
    {
        try
        {
            
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'fetch');
            formData.append('table', 'language');
            formData.append('languageid', languageid);

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as LanguageModel;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async getLanguageByCode(lang : string)
    {
        try
        {
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'lang');
            formData.append('lang', lang);
    
            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as LanguageModel;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async getText(contentkey : string, languageid : number)
    {
        try
        {
            
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'dict');
            formData.append('contentkey', contentkey);
            formData.append('languageid', languageid.toString());

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    let model : WebContentModel = response.data as WebContentModel;
                    return model.content;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async getChapter(chapterid : string)
    {
        try
        {
            
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'fetch');
            formData.append('table', 'chapter');
            formData.append('chapterid', chapterid);

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ChapterModel;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async getTranslation(chapterid : string, languageid : string)
    {
        try
        {
            
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'fetch');
            formData.append('table', 'translation');
            formData.append('chapterid', chapterid);
            formData.append('languageid', languageid);

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TranslationModel;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async getBanner(chapterid : string)
    {
        try
        {
            
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'banner');
            formData.append('chapterid', chapterid);

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as FileModel;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async getFile(fileid : string)
    {
        try
        {
            
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'fetch');
            formData.append('table', 'file');
            formData.append('fileid', fileid);

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as FileModel;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async getMediaType(mediatypeid : string)
    {
        try
        {
            
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'fetch');
            formData.append('table', 'mediatype');
            formData.append('mediatypeid', mediatypeid);

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as MediaTypeModel;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async getQRCode(lang : string, fromLanguageId : number, toLanguageId : number, selectedChapters : string)
    {
        try
        {
            
            let formData : FormData = new FormData();
            formData.append('api', 'index');
            formData.append('cmd', 'qrcode');
            formData.append('lang', lang);
            formData.append('fromlanguage', fromLanguageId.toString());
            formData.append('tolanguage', toLanguageId != null ? toLanguageId.toString() : '0');
            formData.append('chapters', selectedChapters);

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }

    public static async SendMessage(email : string, message : string)
    {
        try
        {
            let formData : FormData = new FormData();
            formData.append('api', 'contact');
            formData.append('cmd', 'message');
            formData.append('email', email);
            formData.append('message', message);
            formData.append('token', '72012b3db8b4fe25d94fe63b7916c520fbd63928471b81e7016526e57187ad46a3d3e3b0291b73540971df7643f748eb7bc76c62115682e44a0bb1311a88ca18');

            let response = await axios.post(`${ApiService.API_PATH}`, formData);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data;
                }
                else
                {
                    console.log(response);
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }
    }
}
