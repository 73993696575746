import { Vue, Options } from 'vue-class-component';
import 'element-plus/theme-chalk/index.css';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import ChapterItem from '@/components/ChapterItem/ChapterItem.vue';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
        ChapterItem
    },
})
   

export default class Info extends Vue 
{
    private lang : string = 'English';
    private langModel : LanguageModel | undefined = {} as LanguageModel;
    private chapterModels : ChapterModel[] | undefined = [] as ChapterModel[];
    private selectedLanguageId : number = 0;
    private dictWelcome : string | undefined = 'Welcome';
    private dictInfoHeader : string | undefined = 'Explain TB offers tuberculosis information in more than 30 languages.';

    public async mounted()
    {
        // get language from params
        this.lang = String(this.$route.params.lang);

        // load corresponding language model from db
        this.langModel = await ApiService.getLanguageByCode(this.lang);

        // load chapters from db
        this.chapterModels = await ApiService.getAllChapters();

        // load text in selected language
        await this.loadDict();
    }

    private async loadDict()
    {
        if (this.langModel)
        {
            this.dictWelcome = await ApiService.getText("WELCOME", this.langModel.languageid);
            this.dictInfoHeader = await ApiService.getText("INFO_HEADER", this.langModel.languageid);
        }
    }
}
