import { Vue, Options } from 'vue-class-component';
//import 'element-ui/lib/theme-chalk/index.css';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop
    },
})
    

export default class Chapter extends Vue 
{
    private lang : number = 0;
    private chapterid : string = '';
    private languageid : string = '';
    private audioid : string = '';
    private loading : boolean = true;
    private hasAudio : boolean = false;
    private audioFilePath : string = '';
    private chapterModel : ChapterModel | undefined;
    private translationModel : TranslationModel | undefined;
    private bannerFileModel : FileModel | undefined;
    private audioFileModel : FileModel | undefined;
    private mediaTypeModel : MediaTypeModel | undefined;
    private languageModel : LanguageModel | undefined;

    public async mounted()
    {
        // get chapterid from route params
        this.chapterid = String(this.$route.params.chapterid);

        // get languageid from route params
        this.languageid = String(this.$route.params.languageid);

        // load languageid from local storage
        if (localStorage.getItem("languageid") != null)
        {
            let id : string | null = localStorage.getItem("languageid");
            if (id) this.languageid = id;
        }

        // get translation for this chapter
        this.translationModel = await ApiService.getTranslation(this.chapterid, this.languageid);

        if (this.translationModel)
        {
            // load language settings
            this.languageModel = await ApiService.getLanguage(this.translationModel.languageid.toString());

            // load audio file for chapter (if exists)
            this.audioid = this.translationModel.audiofileid.toString();
            
            this.audioFileModel = await ApiService.getFile(this.audioid);

            if (this.audioFileModel)
            {
                this.audioFilePath = `https://www.explaintb.org/backend/backend/web/upload/${this.audioFileModel.filename}`;
                this.mediaTypeModel = await ApiService.getMediaType(this.audioFileModel.mediatypeid.toString());
                this.hasAudio = (this.mediaTypeModel != null);
            }

            // load banner file for chapter
            this.bannerFileModel = await ApiService.getBanner(this.translationModel.chapterid.toString());

            if (this.bannerFileModel)
            {
                this.loading = false;
            }
        }
    }

    private onLanguageChange(languageid : string)
    {
        this.$router.push(`/chapter/${this.chapterid}/${languageid}`).catch(()=>{});
    }
}
