import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-091e47fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chapter" }
const _hoisted_2 = ["dir"]
const _hoisted_3 = ["dir"]

export function render(_ctx, _cache) {
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock(_Transition, { name: "el-fade-in-linear" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass('chapter-'+_ctx.displayType+'-'+_ctx.firstTextAlign)
        }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.firstTitle), 1),
          _createElementVNode("p", {
            class: "chapter-text",
            dir: _ctx.firstTextAlign == 'right' ? 'rtl' : 'ltr'
          }, _toDisplayString(_ctx.firstText), 9, _hoisted_2)
        ], 2), [
          [_vShow, !_ctx.loading]
        ]),
        (!_ctx.loading && _ctx.secondLangId && _ctx.secondLangId > 0)
          ? _withDirectives((_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass('chapter-right-'+_ctx.secondTextAlign)
            }, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.secondTitle), 1),
              _createElementVNode("p", {
                class: "chapter-text",
                dir: _ctx.secondTextAlign == 'right' ? 'rtl' : 'ltr'
              }, _toDisplayString(_ctx.secondText), 9, _hoisted_3)
            ], 2)), [
              [_vShow, !_ctx.loading]
            ])
          : _createCommentVNode("", true)
      ])), [
        [_directive_loading, _ctx.loading]
      ])
    ]),
    _: 1
  }))
}