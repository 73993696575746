import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f957ee10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = ["dir"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  controls: "",
  class: "audio-player"
}
const _hoisted_5 = ["src", "type"]

export function render(_ctx, _cache) {
  const _component_NavBarTop = _resolveComponent("NavBarTop")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NavBarTop),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("h1", {
            key: 0,
            class: _normalizeClass('title-'+_ctx.languageModel.align),
            dir: _ctx.languageModel.align == 'left' ? 'ltr' : 'rtl'
          }, _toDisplayString(_ctx.translationModel.title), 11, _hoisted_2))
        : _createCommentVNode("", true),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createBlock(_component_el_col, {
                key: 0,
                xs: 22,
                sm: 16,
                md: 14,
                lg: 12,
                class: "image"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    class: "banner",
                    src: 'data:image/png;base64,'+_ctx.bannerFileModel.data
                  }, null, 8, _hoisted_3),
                  (_ctx.hasAudio)
                    ? (_openBlock(), _createElementBlock("audio", _hoisted_4, [
                        _createElementVNode("source", {
                          src: _ctx.audioFilePath,
                          type: _ctx.mediaTypeModel.contenttype
                        }, null, 8, _hoisted_5),
                        _createTextVNode(" Your browser does not support the audio element. ")
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.loading)
            ? (_openBlock(), _createBlock(_component_el_col, {
                key: 1,
                xs: 22,
                sm: 12,
                md: 10,
                lg: 9,
                class: _normalizeClass('text-'+_ctx.languageModel.align),
                dir: _ctx.languageModel.align == 'right' ? 'rtl' : 'ltr'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translationModel.content), 1)
                ]),
                _: 1
              }, 8, ["class", "dir"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])), [
      [_directive_loading, _ctx.loading]
    ])
  ]))
}